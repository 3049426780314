import React from "react"
import { Table, message } from "antd"
import { navigate } from "gatsby"

import { getOrderList } from "../api/order"
import Layout from "../layouts"

import "./orders.css"

class OrderList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initOrderList()
  }

  initOrderList = async () => {
    const res = await getOrderList()
    const data = res.data.data
    if (data) {
      this.setState({ data })
      // const { themeList, pageNum } = data
      // this.setState({ themes: themeList, loading: false, total: pageNum, currentPage })
    } else {
      message.error("加载订单列表出错")
    }
  }

  handleNextPay = (event, record) => {
    event.stopPropagation();
    navigate(`/items?orderId=${record.outId}`)
  }

  render() {
    const { data } = this.state

    const columns = [
      {
        title: "订单号",
        dataIndex: "outId",
        key: "outId",
      },
      {
        title: "产品名称",
        dataIndex: "itemName",
        key: "itemName",
      },
      {
        title: "产品价格",
        dataIndex: "itemPrice",
        key: "itemPrice",
        render: price => `${price / 100}  元`,
      },
      {
        title: "订单状态",
        dataIndex: "status",
        key: "status",
        render: status => {
          if (status === 0) {
            return "待支付"
          } else if (status === 1) {
            return "已支付"
          } else if (status === 2) {
            return "交易成功"
          } else if (status === -1) {
            return "已过期"
          }
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        render: time => time.slice(0, -9).replace("T", " "),
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => record.status === 0 && <a onClick={(e) => this.handleNextPay(e, record)}>继续支付</a>,
      },
    ]
    return (
      <Layout isSpacerDisplay={true} title="管理订单">
        <div className="mdnice-orders-container">
          <div className="mdnice-orders-table-container">
            <Table bordered columns={columns} dataSource={data} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default OrderList
